import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import SEO from '../meta/seo';

const Legal = ({ data }) => (
  <Layout>
    <SEO title={data.markdownRemark.frontmatter.title} />

    <div className="container space-around">
      <h1 className="text-main-title max-w-content mb-8 md:mb-12 xl:mb-16">
        {data.markdownRemark.frontmatter.title}.
      </h1>
      <div
        className="text-prose M16 md:M18"
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />
    </div>
  </Layout>
);

Legal.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
      }),
    }),
  }),
};

export default Legal;

export const query = graphql`
  query Legal($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
      }
    }
  }
`;
